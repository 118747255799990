<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      label-placeholder="First Name"
      name="firstName"
      :placeholder='this.$t("_register.First_name")'
      v-model="firstName"
      class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>

    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      label-placeholder="Last Name"
      name="lastName"
      :placeholder='this.$t("_register.Last_name")'
      v-model="lastName"
      class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>

    <vs-input
      v-validate="'required|alpha_dash|min:3'"
      data-vv-validate-on="blur"
      label-placeholder="User Name"
      name="displayName"
      :placeholder='this.$t("_register.User_name")'
      v-model="displayName"
      class="w-full"/>
    <span class="text-danger text-sm">{{ errors.first('displayName') }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      :placeholder='this.$t("_register.Email")'
      placeholder="Email"
      v-model="email"
      class="w-full mt-6"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:6|max:32'"
      name="password"
      label-placeholder="Password"
      :placeholder='this.$t("_register.Password")'
      v-model="password"
      class="w-full mt-6"/>
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <vs-input
      type="password"
      v-validate="'min:6|max:32|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      :placeholder='this.$t("_register.ConfirmPassword")'
      v-model="confirm_password"
      class="w-full mt-6"/>
    <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>

    <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">{{$t("_register.Accept_terms_conditions")}}</vs-checkbox>
    <vs-button type="border" to="/pages/login" class="mt-6">{{$t("_register.Login")}}</vs-button>
    <vs-button class="float-right mt-6" @click="registerUserJWt" :disabled="!validateForm">{{$t("_register.Register")}}</vs-button>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        password: '',
        confirm_password: '',
        isTermsConditionAccepted: true
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.displayName != '' && this.email != '' && this.password != '' && this.confirm_password != '' && this.isTermsConditionAccepted === true;
      }
    },
    methods: {
      checkLogin() {
        // If user is already logged in notify
        if (this.$store.state.auth.isUserLoggedIn()) {

          // Close animation if passed as payload
          // this.$vs.loading.close()

          this.$vs.notify({
            title: this.$t('_register.Login_Attempt'),
            text: this.$t('_register.You_are_already_logged_in'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          });

          return false
        }
        return true
      },
      registerUserJWt() {
        // If form is not validated or user is already login return
        if (!this.validateForm || !this.checkLogin()) return;

        const payload = {
          userDetails: {
            firstName: this.firstName,
            lastName: this.lastName,
            displayName: this.displayName,
            email: this.email,
            password: this.password,
            confirmPassword: this.confirm_password,
            sendEmail: true
          },
          notify: this.$vs.notify
        };
        this.$store.dispatch('auth/registerUserJWT', payload)
          .then((response) => {
            if(response) {
              this.$vs.loading.close();
              this.$acl.change(response);
            } else{
              this.$goc.notify.info({ message: this.$t('_register.message') });
              setTimeout(()=>{
                this.$goc.page.load("/pages/login");
              }, 2000);
            }

          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: this.$t('_register.User_already_exists'),
              text: this.$t('_register.Please_check_your_Email_or_Username'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
      }
    }
  }
</script>
